import React, { useCallback, useRef, useState } from 'react';
import QRCode, { QRCodeRef } from 'react-fancy-qrcode';

import logo from '../assets/images/symbol.png';
import { Typography } from '@material-ui/core';

const QRCodePaymentPage = () => {
	const queryParams = new URLSearchParams(window.location.search);
	const amount = queryParams.get('amount') ?? null;
	const vs = queryParams.get('vs') ?? null;

	return <QRCodeGenerator p_amount={amount} p_vs={vs} />;
};

const QRCodeGenerator = ({ p_amount, p_vs }) => {
	const SPD_VERSION = '1.0';
	const ACCOUNT = 'CZ2108000000006694847309';
	const ACCOUNT_SHORT = '6694847309/0800';
	const AMOUNT = p_amount;
	const CURRENCY = 'CZK';
	const MESSAGE = null;
	const RECIPIENT_NAME = 'YOUKLID VAM DEKUJE';
	const VARIABLE_SYMBOL = p_vs;
	const PAYMENT_TYPE = 'IP'; // instant payment
	const X_URL = 'https://youklid.cz/qr-platba';

	function spd_link() {
		const parts = [
			`SPD*${SPD_VERSION}`,
			ACCOUNT ? `ACC:${ACCOUNT}` : null,
			AMOUNT != null ? `AM:${Number(AMOUNT).toFixed(2).replace('.', '.')}` : null,
			CURRENCY ? `CC:${CURRENCY}` : null,
			MESSAGE ? `MSG:${MESSAGE}` : null,
			RECIPIENT_NAME ? `RN:${RECIPIENT_NAME}` : null,
			VARIABLE_SYMBOL ? `X-VS:${VARIABLE_SYMBOL}` : null,
			PAYMENT_TYPE ? `PT:${PAYMENT_TYPE}` : null,
			X_URL ? `X-URL:${X_URL}` : null,
		];

		return parts.filter(Boolean).join('*');
	}

	const svgRef = useRef();

	const download = useCallback(() => {
		if (!svgRef.current) return;

		const svg = svgRef.current;

		// Serialize SVG to a string
		const serializer = new XMLSerializer();
		const svgData = serializer.serializeToString(svg);

		// Convert SVG to a Blob URL
		const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
		const url = URL.createObjectURL(svgBlob);

		// Create a new image and wait for it to load
		const img = new Image();
		img.crossOrigin = 'anonymous'; // Ensure CORS issues don't prevent downloading
		img.onload = () => {
			// Create a canvas
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');

			// Set canvas size based on SVG size
			const svgSize = svg.getBoundingClientRect();
			canvas.width = svgSize.width;
			canvas.height = svgSize.height;

			// Draw the image onto the canvas
			ctx.drawImage(img, 0, 0);
			URL.revokeObjectURL(url); // Clean up URL

			// Add text above the QR code
			ctx.font = 'bold 24px Arial';
			ctx.textAlign = 'center';
			ctx.fillStyle = '#333333';
			ctx.fillText('Platba QR kódem', canvas.width / 2, 60);

			// Add text below the QR code
			ctx.font = 'bold 24px Arial';
			ctx.textAlign = 'center';
			ctx.fillStyle = '#333333';
			ctx.fillText(Number(AMOUNT).toFixed(2).replace('.', ',') + ' ' + CURRENCY, canvas.width / 2, svgSize.height - 45);

			// Convert canvas to PNG and trigger download
			const pngUrl = canvas.toDataURL('image/png');
			const link = document.createElement('a');
			link.href = pngUrl;
			link.download = 'youklid_platba.png';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		};

		img.src = url;
	}, []);

	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
			<div style={{ opacity: 0, position: 'absolute', top: -10000 }}>
				<QRCode
					imageSettings={{
						src: logo,
						x: undefined,
						y: undefined,
						height: 50,
						width: 50,
						excavate: true,
					}}
					ref={svgRef}
					value={spd_link()}
					size={500}
					dotScale={0.9}
					margin={150}
					errorCorrection="M"
					color={'#000000'}
					positionColor="#000000"
					logo={logo}
				/>
			</div>
			<QRCode
				imageSettings={{
					src: logo,
					x: undefined,
					y: undefined,
					height: 50,
					width: 50,
					excavate: true,
				}}
				value={spd_link()}
				size={300}
				dotScale={0.9}
				margin={30}
				errorCorrection="M"
				color={'#000000'}
				positionColor="#000000"
				logo={logo}
			/>
			<button
				onClick={download}
				style={{
					border: '1px solid #000000',
					padding: '8px 24px',
					borderRadius: 100,
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<Typography style={{ fontFamily: 'agrandir' }}>
					<span style={{ position: 'relative', top: '1px' }}> Stáhnout QR kód </span>
				</Typography>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					fill="none"
					stroke="currentColor"
					strokeLinecap="round"
					strokeLinejoin="round"
					width="30"
					height="30"
					strokeWidth="1"
					style={{ display: 'inline-block', marginLeft: '10px' }}
				>
					<path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"></path> <path d="M7 11l5 5l5 -5"></path> <path d="M12 4l0 12"></path>
				</svg>
			</button>
			<div style={{ height: '50px' }} />
			<Typography style={{ fontFamily: 'agrandir' }} component={'div'}>
				<style>
					{`
					.table {
						display: grid;
						grid-template-columns: auto auto;
						border-collapse: collapse;
						width: 100%;
						border-top: 1px solid #c4c4c4;
						border-left: 1px solid #c4c4c4;
					}
					.table div {
						padding: 8px 16px;
						border-bottom: 1px solid #c4c4c4;
						border-right: 1px solid #c4c4c4;
					}
					@media (max-width: 768px) {
						.table {
							grid-template-columns: auto;
							font-size: 14px;
						}
					}
					.table div > span{
						cursor: pointer;
						display: grid;
						grid-template-columns: auto auto;
						gap: 20px;
						align-items: center;
						justify-items: end;
					}	
					`}
				</style>
				<div className="table">
					<div>Číslo účtu</div>
					<div>
						<span
							onClick={() => {
								navigator.clipboard.writeText(ACCOUNT_SHORT);
							}}
						>
							{ACCOUNT_SHORT}
							<CopyButton />
						</span>
					</div>
					<div>Částka</div>
					<div>
						<span
							onClick={() => {
								navigator.clipboard.writeText(AMOUNT);
							}}
						>
							{Number(AMOUNT).toFixed(2).replace('.', ',') + ' ' + CURRENCY}
							<CopyButton />
						</span>
					</div>
					<div>Variabilní symbol</div>
					<div>
						<span
							onClick={() => {
								navigator.clipboard.writeText(VARIABLE_SYMBOL);
							}}
						>
							{VARIABLE_SYMBOL}
							<CopyButton />
						</span>
					</div>
				</div>
			</Typography>
		</div>
	);
};

const CopyButton = () => {
	return (
		<div style={{ display: 'inline-flex', alignItems: 'center', border: '1px solid #000000', padding: '4px 12px', borderRadius: 100, cursor: 'pointer' }}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 24 24"
				fill="none"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				width="20"
				height="20"
				strokeWidth="1"
				style={{ display: 'inline', marginRight: '4px' }}
			>
				<path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z"></path>
				<path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1"></path>
			</svg>
			<span style={{ fontFamily: 'agrandir', fontSize: 14, position: 'relative', top: '1px' }}>Kopírovat</span>
		</div>
	);
};

export default QRCodePaymentPage;
