import React from 'react';

function LayoutCard({ children, marginBottom = 24, omit = false, id, style }) {
	if (omit) return children;
	return (
		<div
			style={{
				background: '#fff',
				boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
				padding: '16px 16px 16px 16px',
				borderRadius: '16px',
				marginBottom: marginBottom,
				...style,
			}}
			id={id}
		>
			{children}
		</div>
	);
}

export const DialogCard = ({ children, onClick, refPass }) => {
	return (
		<dialog
			onClick={onClick}
			ref={refPass}
			className="w-1200"
			style={{
				background: '#fff',
				boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
				padding: '16px 16px 16px 16px',
				borderRadius: '16px',
			}}
		>
			{children}
		</dialog>
	);
};

export default LayoutCard;
