import React, { useEffect, useState } from 'react';
import './styles/Form.css';
import { Alert, Button, CircularProgress, Collapse, Grid, TextField, Typography } from '@mui/material';
import HiringButton, { SubmitHiringButton } from './HiringButton';

const Form = ({ t }) => {
	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [city, setCity] = useState(null);
	const [interview, setInterview] = useState(null);
	const [slotId, setSlotId] = useState(null);
	const [source, setSource] = useState('');

	const [sortedFaceToFace, setSortedFaceToFace] = useState([]);
	const [sortedVirtual, setSortedVirtual] = useState([]);

	const [loading, setLoading] = useState(false);

	const [errors, setErrors] = useState([]);
	const [success, setSuccess] = useState(null);

	const [secret, setSecret] = useState(-10);

	const setInterviewComplex = e => {
		setInterview(e.target.value);
		if (e.target.value == 'call-me') {
			setSlotId(-1);
		}
		else{
			setSlotId(null);
		}
	};

	useEffect(() => {
		setInterview(null);
		setSlotId(null);
	}, [city]);

	// useEffect(() => {
	// 	setSlotId(null);
	// }, [interview]);

	useEffect(() => {
		fetch(`https://system.youklid.cz/api/slots?variant_id=1`)
			.then(response => response.json())
			.then(data => {
				if (data.status == 'ok') {
					const sorted = sortSlots(data.slots);
					setSortedFaceToFace(sorted);
				}
				setLoading(false);
			})
			.catch(error => {
				console.error('Error fetching data:', error);
				setLoading(false);
			});
	}, [city]);

	useEffect(() => {
		fetch(`https://system.youklid.cz/api/slots?variant_id=4`)
			.then((response) => response.json())
			.then((data) => {
				if (data.status == 'ok') {
					const sorted = sortSlots(data.slots);
					setSortedVirtual(sorted);
				}
				setLoading(false);
			})
			.catch((error) => {
				console.error('Error fetching data:', error);
				setLoading(false);
			});
	}, [city]);

	const sortSlots = slots => {
		const groupedAppointments = slots
		.filter(slot => {
			return slot.location == null || slot.location.includes(locations[city]?.name);
		})
		.reduce((acc, appointment) => {
			const date = appointment.appointment_slot_begin.split(' ')[0];
			if (!acc[date]) {
				acc[date] = [];
			}
			acc[date].push(appointment);
			return acc;
		}, {});

		// Sort the grouped appointments by day
		const sortedGroupedAppointments = Object.keys(groupedAppointments)
			.sort()
			.map((acc, key) => {
				let result = {};
				result.date = acc;
				result.appointments = groupedAppointments[acc];
				return result;
			}, {});

		return sortedGroupedAppointments;
	};

	const submitApplication = () => {
		const formData = new FormData();
		formData.append('slot_id', slotId);
		formData.append('name', name);
		formData.append('email', email);
		formData.append('phone', phone);
		formData.append('city', locations[city]?.name);
		formData.append('source', source);

		const requestOptions = {
			method: 'POST',
			body: formData,
			redirect: 'follow',
		};

		fetch('https://system.youklid.cz/api/reserve', requestOptions)
			.then(response => response.json())
			.then(data => {
				if (data?.status != 'ok') {
					setErrors(Object.values(data));
				} else {
					setName('');
					setEmail('');
					setPhone('');
					setCity(null);
					setInterview(null);
					setSlotId(null);
					setSource('');
					setLoading(false);
					setErrors([]);
					setSuccess(t.form.success);
					if (source == '') {
						window.location = '/dekujeme-za-zajem';
					}
				}
			})
			.catch(error => console.error(error));
	};

	const locations = [
		{
			name: 'Praha',
			url: '/img/thumbnails/praha.png',
			interviews: ['face-to-face', 'call-me'],
		},
		{
			name: 'Brno',
			url: '/img/thumbnails/brno.png',
			interviews: ['face-to-face', 'virtual', 'call-me'],
		},
		{
			name: 'Plzeň',
			url: '/img/thumbnails/plzen.png',
			interviews: ['virtual', 'call-me'],
		},
		{
			name: 'Liberec',
			url: '/img/thumbnails/liberec.png',
			interviews: ['virtual', 'call-me'],
		},
		// {
		// 	name: 'Hradec Králové',
		// 	url: '/img/thumbnails/hradec.png',
		// 	interviews: ['virtual'],
		// },
		// {
		// 	name: 'Pardubice',
		// 	url: '/img/thumbnails/pardubice.png',
		// 	interviews: ['virtual'],
		// },
		// {
		// 	name: 'Olomouc',
		// 	url: '/img/thumbnails/olomouc.png',
		// 	interviews: ['virtual'],
		// },
		// {
		// 	name: 'České Budějovice',
		// 	url: '/img/thumbnails/budejovice.png',
		// 	interviews: ['virtual'],
		// },
		// {
		// 	name: 'Ostrava',
		// 	url: '/img/thumbnails/ostrava.png',
		// 	interviews: ['virtual'],
		// },
		// {
		// 	name: 'Karlovy Vary',
		// 	url: '/img/thumbnails/vary.png',
		// 	interviews: ['virtual'],
		// },
		// {
		// 	name: 'Zlín',
		// 	url: '/img/thumbnails/zlin.png',
		// 	interviews: ['virtual'],
		// },
		// {
		// 	name: 'Jihlava',
		// 	url: '/img/thumbnails/jihlava.png',
		// 	interviews: ['virtual'],
		// },
		// {
		// 	name: 'Ústí nad Labem',
		// 	url: '/img/thumbnails/usti.png',
		// 	interviews: ['virtual'],
		// },
	];

	return (
		<div className="form-wrapper">
			<div id="formular" style={{ position: 'relative', top: -140 }} />
			<div className="form">
				<div className="card">
					<div className="text">
						<Typography variant="h2" className="width-300" onClick={() => setSecret(prev => prev + 1)}>
							{t.form.heading}
						</Typography>
						{/* <Typography className="width-300">{t.form.text}</Typography> */}
						<Typography component="div">
							<ol>
								{t.form.steps.map((step, index) => (
									<li key={index}>{step}</li>
								))}
							</ol>
						</Typography>
						<Typography component="div" style={{ marginTop: '20px' }}>
							{t.form.contact_text}
							{t.form.contact_phone_link}
						</Typography>
						<img className="contact-photo" src={t.form.contact_photo_url} alt={t.form.contact_name} />
					</div>
					<div className="details">
						<TextField
							size="small"
							name="name"
							label={
								<>
									{t.form.name} <span style={{ color: 'red' }}>*</span>
								</>
							}
							value={name}
							onChange={e => setName(e.target.value)}
							className="colspan-2"
						/>
						<TextField
							size="small"
							name="phone"
							label={
								<>
									{t.form.phone} <span style={{ color: 'red' }}>*</span>
								</>
							}
							value={phone}
							onChange={e => setPhone(e.target.value)}
						/>
						<TextField
							size="small"
							name="email"
							label={
								<>
									{t.form.email} <span style={{ color: 'red' }}>*</span>
								</>
							}
							value={email}
							onChange={e => setEmail(e.target.value)}
						/>
						{secret >= 0 && (
							<TextField
								className="colspan-2"
								size="small"
								name="source"
								label={<>{t.form.source}</>}
								value={source}
								onChange={e => setSource(e.target.value)}
							/>
						)}

						<div className="section-wrapper colspan-2">
							<Typography className="section-label">
								{t.form.location} <span style={{ color: 'red' }}>*</span>
							</Typography>
							<div className="locations">
								{locations.map((location, index) => (
									<div
										key={index}
										className={(city === index ? 'city_button city_button_selected' : 'city_button') + ' effect'}
										onClick={() => setCity(index)}
										style={{ backgroundImage: 'url(' + location.url + ')' }}
									>
										<div>{location.name}</div>
									</div>
								))}
								<div key={'more'} className={'city_button no_effect'} style={{ backgroundImage: 'url(/img/thumbnails/more.png)' }}>
									<div>{t.form.more}</div>
								</div>
							</div>
						</div>
						<div className="section-wrapper colspan-2">
							<Collapse in={city != null}>
								<Typography className="section-label">
									{t.form.variant} <span style={{ color: 'red' }}>*</span>
								</Typography>
								<Typography component="div">
									<div className="radios">
										{locations[city]?.interviews.map(variant => (
											<label>
												<input
													type="radio"
													name="interview"
													value={variant}
													checked={interview == variant}
													onChange={setInterviewComplex}
												/>
												<span className="shift-text">
													{variant == 'virtual' && <>{t.form.virtual}</>}
													{variant == 'face-to-face' && <>{t.form.face_to_face}</>}
													{variant == 'call-me' && <>{t.form.call_me}</>}
												</span>
											</label>
										))}
									</div>
								</Typography>
							</Collapse>
						</div>
						{interview != null && (
							<div className="appointments">
								{interview == 'face-to-face' && (
									<>
										<Typography className="section-label">
											{t.form.choose_face_to_face} <span style={{ color: 'red' }}>*</span>
										</Typography>
										<div className="options">
											{loading && (
												<Grid container justifyContent={'center'} style={{ margin: '40px 0' }}>
													<Grid item>
														<CircularProgress size="40px" style={{ color: '#004d58' }} />
													</Grid>
												</Grid>
											)}
											<div className="options-grid">
												{sortedFaceToFace.map((day, index) => (
													<div key={index}>
														<Typography className="date">{format_date(day.date)}</Typography>
														<Grid container gap={2}>
															{day.appointments.map((appointment, appointment_index) => (
																<Button
																	size="small"
																	color="secondary"
																	variant={slotId === appointment.appointment_slot_id ? 'contained' : 'outlined'}
																	key={appointment_index}
																	onClick={() => setSlotId(appointment.appointment_slot_id)}
																>
																	<span style={{ position: 'relative', top: 2 }}>
																		{new Date(appointment.appointment_slot_begin).toLocaleTimeString('cs-CZ', {
																			hour: 'numeric',
																			minute: 'numeric',
																		})}
																	</span>
																</Button>
															))}
														</Grid>
													</div>
												))}
											</div>
											{!loading && sortedFaceToFace.length <= 0 && <Typography>{t.form.no_slots_face_to_face}</Typography>}
										</div>
									</>
								)}
								{interview == 'virtual' && (
									<>
										<Typography className="section-label">
											{t.form.choose_virtual} <span style={{ color: 'red' }}>*</span>
										</Typography>
										<div className="options">
											{loading && (
												<Grid container justifyContent={'center'} style={{ margin: '40px 0' }}>
													<Grid item>
														<CircularProgress size="40px" style={{ color: '#004d58' }} />
													</Grid>
												</Grid>
											)}
											<div className="options-grid">
												{sortedVirtual.map((day, index) => (
													<div key={index}>
														<Typography className="date">{format_date(day.date)}</Typography>
														<Grid container gap={2}>
															{day.appointments.map((appointment, appointment_index) => (
																<Button
																	size="small"
																	color="secondary"
																	variant={slotId === appointment.appointment_slot_id ? 'contained' : 'outlined'}
																	key={appointment_index}
																	onClick={() => setSlotId(appointment.appointment_slot_id)}
																>
																	<span style={{ position: 'relative', top: 2 }}>
																		{new Date(appointment.appointment_slot_begin).toLocaleTimeString('cs-CZ', {
																			hour: 'numeric',
																			minute: 'numeric',
																		})}
																	</span>
																</Button>
															))}
														</Grid>
													</div>
												))}
											</div>
											{!loading && sortedVirtual.length <= 0 && <Typography>{t.form.no_slots_virtual}</Typography>}
										</div>
									</>
								)}
							</div>
						)}
						<div className="colspan-2 errors">
							{errors.map(field => {
								return field.map(error => (
									<Alert severity="info" className="error">
										<strong>Chyba.</strong> {error}
									</Alert>
								));
							})}
							{success != null && (
								<Alert severity="success" className="error">
									{success}
								</Alert>
							)}
						</div>
						<div style={{ display: 'flex', justifyContent: 'flex-end' }} className="colspan-2">
							<SubmitHiringButton t={t} onClick={() => submitApplication()} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

function format_date(date) {
	const formatted = new Date(date).toLocaleDateString('cs-CZ', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });

	return formatted[0].toUpperCase() + formatted.slice(1);
}

export default Form;
