import { Alert } from '@mui/material';
import React from 'react';
import ContentWrapper from '../Wrapper/ContentWrapper';
import BackButton from '../BackButton';
function PaymentRedirect(props) {
	return (
		<ContentWrapper showCTA={false}>
			{/* <BackButton /> */}
			<div style={{ height: 100 }} />
			<Alert severity="info" gutterBottom>
				Načetli jste QR kód a nezobrazila se vám platba? Zkuste ho načíst znovu svou bankovní aplikací.
			</Alert>
			<div style={{ height: 100 }} />
		</ContentWrapper>
	);
}

export default PaymentRedirect;
